import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { StateConsumer } from '../context/StateContext'
import {
  attachProductBuilder,
  detachProductBuilder,
  // detatchThreeJs
} from '../helpers/DomHelper'

function Create(props){


  useEffect(() => {
    attachProductBuilder()

    return () => {
      detachProductBuilder()
      // detatchThreeJs()
    }

  });

  return (
    <>
      <Layout type="fullHeight">
        <StateConsumer>
          {({updateModal, modal}) => {
            return (
              <>
                <link href="https://utilities.bocklighting.com/cocoon/dev/css/main.css" rel="stylesheet" />
                <div id="pb_package">FL1</div>
                <div id="pb_root" data-height="calc(100vh - 94px)">&nbsp;</div>
              </>
            )
          }}
        </StateConsumer>
      </Layout>
    </>
  )
}

export default Create;
